import { FC, useCallback, useRef } from 'react';
import { useAppSelector, useRoomListeners } from 'hooks';
import { RemoteTrack, RoomEvent } from 'livekit-client';
import { roomSelector } from 'store/slices/room/selectors';

import { CopyIcon } from 'components/CopyIcon';
import { Presence } from 'components/Presence';
import { Button } from 'components/ui';

interface StreamControllerProps {
  onFinish: () => void;
}

export const StreamController: FC<StreamControllerProps> = ({ onFinish }) => {
  const { url, streamKey } = useAppSelector(roomSelector);

  const videoRef = useRef<HTMLVideoElement>(null);

  const attachTracks = useCallback(
    (track: RemoteTrack) => {
      if (videoRef.current) {
        const ref = videoRef.current;

        track.attach(ref);

        ref.muted = true;
        ref.volume = 0;
      }
    },
    [videoRef]
  );

  useRoomListeners({
    [RoomEvent.TrackSubscribed]: attachTracks,
  });

  return (
    <>
      <div className="flex flex-col gap-[16px]">
        <div className="flex flex-row justify-between">
          <span className="tpg-h4">Эфир запущен</span>
          <Presence />
        </div>
        <div className="flex overflow-hidden aspect-video justify-center relative w-full border-solid border-tpg_light border-[1px] rounded-[10px] bg-light">
          <video className="w-full" muted ref={videoRef} />
        </div>
      </div>
      <span className="tpg-b1">Ссылка RTMP</span>
      <div className="flex flex-col gap-[8px]">
        <div className="flex flex-row justify-between items-center w-full py-[10px] px-[16px] rounded-[5px] bg-light tpg-b2 text-bright_product">
          <span>{url}</span>
          <CopyIcon value={url} />
        </div>
        <div className="flex h-[44px] flex-row justify-between items-center w-full py-[10px] px-[16px] rounded-[5px] bg-light">
          <span className="h-[12px]">{streamKey}</span>
          <CopyIcon value={streamKey} />
        </div>
        <div className="flex h-[44px] flex-row justify-between items-center w-full py-[10px] px-[16px] rounded-[5px] bg-light gap-[5px]">
          <div className="w-full overflow-hidden text-ellipsis">
            <span className="h-[12px]">{`${url}/${streamKey}`}</span>
          </div>
          <CopyIcon value={`${url}/${streamKey}`} />
        </div>
      </div>
      <Button onClick={onFinish} title="Завершить" className="w-full" />
    </>
  );
};
