import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { navigation } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getAccountMeThunk } from 'store/slices/auth/actions';
import { authSelector } from 'store/slices/auth/selectors';

import { StreamLaunch } from './StreamLaunch';

export const StreamLaunchScreen = () => {
  const { username, id: accountId, role } = useAppSelector(authSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!username) {
      dispatch(getAccountMeThunk());
    }
  }, []);

  if (!username || !accountId)
    return <Navigate to={role ? navigation[role] : '/'} replace />;

  return (
    <div className="flex items-center justify-center h-full">
      <StreamLaunch username={username} />
    </div>
  );
};
