import { FC, MouseEvent, useRef, useState } from 'react';
import { useAppDispatch, useClickOutside } from 'hooks';
import { IAccountRole } from 'interfaces';
import { deleteClanMemberThunk } from 'store/slices/clan/actions';
import { rolesTranslateMap } from 'types/player';

import { DeleteModal } from 'components/DeleteModal';
import { Button } from 'components/ui';

interface AccessCardProps {
  clanId: string;
  userId: string;
  name: string;
  originClanName: string;
  roles: IAccountRole[];
}

export const AccessCard: FC<AccessCardProps> = ({
  clanId,
  userId,
  name,
  originClanName,
  roles,
}) => {
  const [isShowDeleteButton, setShowDeleteButton] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  useClickOutside<HTMLDivElement, void>(containerRef, () =>
    setShowDeleteButton(false)
  );

  const handleOpenModal = (event: MouseEvent) => {
    event.stopPropagation();

    setOpenDeleteModal(true);
  };

  const handleRightClick = (e: MouseEvent) => {
    e.preventDefault();

    setShowDeleteButton(true);
  };

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
  };

  const handleRemovePlayer = () => {
    dispatch(
      deleteClanMemberThunk({
        clanId: clanId,
        memberId: userId,
      })
    );

    handleCloseModal();
  };

  const role = roles.find((role) => role.clan_id === clanId)?.role_name;

  return (
    <div
      ref={containerRef}
      onContextMenu={handleRightClick}
      className="flex flex-col border border-solid rounded-[10px] border-dark_product"
    >
      <div className="relative h-[58px] flex flex-col justify-center px-[16px] py-[11px]">
        <span className="tpg-c2 text-tpg_light">Позывной</span>
        <span className="tpg-c1 pointer-events-none">{name}</span>
        {isShowDeleteButton && (
          <Button
            className="absolute left-[140px] top-[15px] rounded-[10px] border border-solid border-tpg_light bg-ultrablack px-[16px] py-[6px] text-tpg_base hover:bg-dark_product"
            title="Удалить"
            onClick={handleOpenModal}
          />
        )}
      </div>
      <div className="h-[58px] flex flex-col justify-center px-[16px] py-[11px] border-t border-solid border-t-dark_product">
        <span className="tpg-c2 text-tpg_light">Родное подразделение</span>
        <span className="tpg-c1 pointer-events-none">{originClanName}</span>
      </div>
      <div className="h-[58px] flex flex-col justify-center px-[16px] py-[11px] border-t border-solid border-t-dark_product">
        <span className="tpg-c2 text-tpg_light">Роль</span>
        <span className="tpg-c1 pointer-events-none">
          {role ? rolesTranslateMap[role] : 'Не выбрана'}
        </span>
      </div>
      {isOpenDeleteModal && (
        <DeleteModal
          title="Вы уверены, что хотите удалить пользователя?"
          onClose={handleCloseModal}
          onRemove={handleRemovePlayer}
        />
      )}
    </div>
  );
};
