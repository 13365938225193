import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LP_INTERVAL_MS } from 'constants/profile';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IRoomParams } from 'interfaces';
import { authSelector } from 'store/slices/auth';
import { clansSelector } from 'store/slices/clan';
import { roomActions, roomSelector } from 'store/slices/room';
import { getAllRoomsThunk, getClanRoomsThunk } from 'store/slices/room/actions';
import { rolesValuesMap } from 'types/player';

import { setHiddenRoomsInSearchParams } from 'utils';

import { Panel } from './Panel';

interface AllClansStreamsPanelProps {
  isFullscreenOpen: boolean;
  onBack: () => void;
}

export const AllClansStreamsPanel: FC<AllClansStreamsPanelProps> = ({
  isFullscreenOpen,
  onBack,
}) => {
  const [openedFullscreenClanRoom, setOpenedFullscreenClanRoom] = useState<{
    livekitName: string;
    clanId: string;
  }>();

  const { treeRooms, rooms: roomsData } = useAppSelector(roomSelector);

  const { clans } = useAppSelector(clansSelector);

  const { id: accountId, roles } = useAppSelector(authSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const rooms = useMemo(
    () =>
      treeRooms.reduce((acc: IRoomParams[], data) => {
        if (data.rooms) {
          const convertedRooms = data.rooms.map((room) => ({
            ...room,
            clanName: data.clanName,
            clanId: data.clanId,
          }));

          acc = [...acc, ...convertedRooms];
        }

        return acc;
      }, []),
    [treeRooms]
  );

  const filteredRooms = useMemo(
    () =>
      rooms.filter(
        (room) =>
          room.accountId === accountId ||
          roles.some(
            (role) =>
              role.clan_id === room.clanId &&
              role.role_id !== rolesValuesMap.user
          )
      ),
    [rooms, accountId]
  );

  const roomList = useMemo(
    () =>
      openedFullscreenClanRoom?.livekitName
        ? filteredRooms.filter(
            (room) => room.livekitName === openedFullscreenClanRoom?.livekitName
          )
        : filteredRooms,
    [filteredRooms, openedFullscreenClanRoom]
  );

  useEffect(() => {
    if (openedFullscreenClanRoom?.clanId) {
      const getRooms = () => {
        if (clans) {
          dispatch(getClanRoomsThunk(openedFullscreenClanRoom.clanId));
        }
      };

      getRooms();

      const roomsInterval = setInterval(getRooms, LP_INTERVAL_MS);

      return () => {
        clearInterval(roomsInterval);
      };
    } else {
      const getRooms = () => {
        if (clans) {
          dispatch(getAllRoomsThunk());
        }
      };

      getRooms();

      const roomsInterval = setInterval(getRooms, LP_INTERVAL_MS);

      return () => {
        clearInterval(roomsInterval);
      };
    }
  }, [clans, openedFullscreenClanRoom]);

  useEffect(() => {
    dispatch(roomActions.setTreeRooms({ clans, searchParams }));
  }, [roomsData, clans]);

  useEffect(() => {
    if (!searchParams.size) {
      const params = setHiddenRoomsInSearchParams(searchParams, treeRooms);
      setSearchParams(params);
    }
  }, [treeRooms, searchParams]);

  useEffect(() => {
    if (!roomList.length && openedFullscreenClanRoom) {
      setOpenedFullscreenClanRoom(undefined);

      return;
    }
  }, [roomList, openedFullscreenClanRoom]);

  const handleFullscreenChange = (
    isFullOpen: boolean,
    livekitName: string,
    clanId?: string
  ) => {
    if (isFullOpen) {
      if (clanId) {
        setOpenedFullscreenClanRoom({ livekitName, clanId });
      }
    } else {
      setOpenedFullscreenClanRoom(undefined);
    }
  };

  console.log(
    'Active rooms :>>',
    filteredRooms.map(({ livekitName }) => livekitName)
  );

  return (
    <Panel
      isFullscreenOpen={isFullscreenOpen}
      openedFullscreenClanRoom={openedFullscreenClanRoom}
      onBack={onBack}
      rooms={roomList}
      onFullscreenChange={handleFullscreenChange}
    />
  );
};
