import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateAccount, updateAccountRole } from 'api/account';
import { changeEmail, changePassword } from 'api/auth';
import {
  createClan,
  deleteClan,
  deleteClanMember,
  getClan,
  getClanMembers,
  getClans,
  setClanMember,
  TSetClanMemberInput,
  updateClan,
} from 'api/clans';
import { ICreateClanThunkData } from 'api/types/clan';
import { AxiosError, AxiosResponse } from 'axios';
import {
  errorMessages,
  errorResponsePlayerMessages,
  successMessages,
  successResponsePlayerMessages,
} from 'constants/messages';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import { IAccountRole, ThunkAPI } from 'interfaces';
import { IClan } from 'interfaces/clan';
import { IPlayer } from 'interfaces/player';
import { TClanResponse } from 'types/clan';
import { TMember } from 'types/clan';
import { rolesReverseMap } from 'types/player';

import { createThunk, getErrorMessage, notify } from 'utils';

import { getAllPlayersThunk, registerWithClanThunk } from '../player/actions';

const getUrls = (
  results: PromiseSettledResult<AxiosResponse<any, any>>[],
  status: 'fullfiled' | 'rejected'
): { url: string; error?: AxiosError<{ code: string; message: string }> }[] =>
  results.map((result: any) =>
    status === 'fullfiled'
      ? { url: result.value.config.url.split('/')[1] }
      : { url: result.reason.config.url.split('/')[1], error: result.reason }
  );

export const getClansThunk = createThunk<TClanResponse[]>(
  `${reducersNames.CLAN}/${asyncActionsNames.GET_CLANS}`,
  getClans
);

export const getClanThunk = createThunk<TClanResponse, string>(
  `${reducersNames.CLAN}/${asyncActionsNames.GET_CLAN}`,
  getClan,
  { errorMessage: errorMessages.GET_CLAN }
);

export const createClanThunk = createAsyncThunk<ThunkAPI, ICreateClanThunkData>(
  `${reducersNames.CLAN}/${asyncActionsNames.CREATE_CLAN}`,
  async (request, thunkAPI) => {
    const { clan, players } = request;

    const { coordinates, admin_id, name, work_area } = clan;

    try {
      const clanData = await createClan({
        // coordinates,
        admin_id,
        name,
        work_area,
      }).then(async (res) => {
        const { data } = res;

        await Promise.all(
          players.map((player) =>
            thunkAPI.dispatch(
              registerWithClanThunk({
                clanId: data.id,
                password: player.password,
                name: player.name,
                roleId: player.role,
                email: player.email,
                isAddedFromClan: true,
              })
            )
          )
        );

        await thunkAPI.dispatch(getClansThunk());

        return data;
      });

      return clanData;
    } catch (error) {
      notify.error(errorMessages.CREATE_CLAN);

      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateClanThunk = createThunk<IClan, IClan>(
  `${reducersNames.CLAN}/${asyncActionsNames.UPDATE_CLAN}`,
  updateClan,
  { errorMessage: errorMessages.UPDATE_CLAN }
);

export const deleteClanThunk = createAsyncThunk<AxiosResponse<string>, string>(
  `${reducersNames.CLAN}/${asyncActionsNames.DELETE_CLAN}`,
  async (request, thunkAPI) => {
    try {
      const data = await deleteClan(request);

      await thunkAPI.dispatch(getClansThunk());

      return data;
    } catch (error) {
      notify.error(errorMessages.DELETE_CLAN);

      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const setClanMemberThunk = createAsyncThunk<
  AxiosResponse<void, void>,
  TSetClanMemberInput
>(
  `${reducersNames.CLAN}/${asyncActionsNames.SET_CLAN_MEMBER}`,
  async (request, thunkAPI) => {
    const { clanId, memberId, roleId } = request;

    try {
      const data = await setClanMember({
        clanId,
        memberId,
        roleId,
      });

      return data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const message = getErrorMessage(error, errorMessages.REGISTER_ERROR);

        notify.error(message);
      }

      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteClanMemberThunk = createAsyncThunk<
  AxiosResponse<any, any>,
  Omit<TSetClanMemberInput, 'roleId'> & { isAddedFromAccount?: boolean }
>(
  `${reducersNames.CLAN}/${asyncActionsNames.DELETE_CLAN_MEMBER}`,
  async (request, thunkAPI) => {
    const { clanId, memberId, isAddedFromAccount } = request;

    try {
      const data = await deleteClanMember({
        clanId,
        memberId,
      });

      if (isAddedFromAccount) {
        thunkAPI.dispatch(getAllPlayersThunk());

        notify.success(successMessages.DELETE_ACCESS);
      }

      return data;
    } catch (error) {
      notify.error(errorMessages.DELETE_CLAN_MEMBER);

      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getClanMembersThunk = createThunk<IPlayer[], string>(
  `${reducersNames.CLAN}/${asyncActionsNames.GET_CLAN_MEMBERS}`,
  getClanMembers,
  { errorMessage: errorMessages.GET_CLAN }
);

export const updateClanMemberThunk = createAsyncThunk<
  Partial<IPlayer>,
  Omit<TMember, 'roles'> & {
    roles?: IAccountRole[];
    clanId?: string;
    newRoleId?: string;
  }
>(
  `${reducersNames.CLAN}/${asyncActionsNames.UPDATE_CLAN_MEMBER}`,
  async (request, thunkAPI) => {
    const { id, name, roles, password, external_id, clanId, newRoleId } =
      request;

    const updateRequests = [];

    let newPlayerData: TMember = { id };

    if (password) {
      const newPasword = changePassword({
        id: String(id),
        password: password,
        password2: password,
      });

      updateRequests.push(newPasword);
    }

    if (external_id && password) {
      const newEmail = changeEmail({
        id,
        email: external_id,
        current_password: password,
      });

      updateRequests.push(newEmail);
    }

    if (newRoleId && clanId) {
      const newRole = updateAccountRole({
        account_id: String(id),
        clan_id: String(clanId),
        role_id: newRoleId,
      });

      updateRequests.push(newRole);
    }

    if (name) {
      const newDataAccount = updateAccount({
        id,
        name,
      });

      updateRequests.push(newDataAccount);
    }

    const results = await Promise.allSettled(updateRequests).then(
      (results) => results
    );

    const successResults = results.filter(
      (result) => result.status === 'fulfilled'
    );

    const errorResults = results.filter(
      (result) => result.status === 'rejected'
    );

    if (successResults.length) {
      const data = getUrls(successResults, 'fullfiled');

      new Set(data).forEach(({ url }) => {
        if (url === 'change-password') {
          newPlayerData = { ...newPlayerData, password };
        }

        if (url === 'change-email') {
          newPlayerData = {
            ...newPlayerData,
            password,
            external_id,
          };
        }

        if (url === 'accounts') {
          if (name) {
            newPlayerData = {
              ...newPlayerData,
              name,
            };
          }

          if (newRoleId && clanId) {
            const convertedData = roles?.reduce(
              (roles: IAccountRole[], role) => {
                roles.push(
                  role.clan_id === clanId
                    ? {
                        ...role,
                        role_id: newRoleId,
                        role_name: rolesReverseMap[newRoleId],
                      }
                    : role
                );

                return roles;
              },
              []
            );

            newPlayerData = {
              ...newPlayerData,
              roles: convertedData,
            };
          }
        }

        notify.success(successResponsePlayerMessages[url]);
      });

      if (successResults.length === updateRequests.length && clanId) {
        thunkAPI.dispatch(getClanThunk(String(clanId)));
      }
    }

    if (errorResults.length) {
      const data = getUrls(errorResults, 'rejected');

      new Set(data).forEach(({ error, url }) => {
        if (error) {
          const message = getErrorMessage(
            error,
            errorResponsePlayerMessages[url]
          );

          notify.error(message);

          return;
        }

        notify.error(errorResponsePlayerMessages[url]);
      });
    }

    return errorResults.length
      ? thunkAPI.rejectWithValue(errorResults)
      : newPlayerData;
  }
);
