import { IClanRoomsAdapted } from 'api/types/room';
import { AxiosError } from 'axios';
import { errorDictionary } from 'constants/errorDictionary';
import { SEPARATOR } from 'constants/livekit';
import {
  IAccountRole,
  IMediaFile,
  IOption,
  IRoom,
  ISelectOption,
  ITag,
  ITreeRooms,
} from 'interfaces';
import { IClanPlayer, IPlayer } from 'interfaces/player';
import { IRecord } from 'interfaces/record';
import { TPlayerInfo } from 'routes/PlayerAdminPanel';
import { rolesTranslateMap } from 'types/player';

import { IFilterValue } from 'components/ui/Table/STableHeader';

import { isVideo } from './monitoring';

export * from './coordinates';
export * from './geospoof';
export * from './helpers/date';
export * from './helpers/string';
export * from './monitoring';
export * as notify from './notifications';
export * from './store';
export * from './tokenStore';

export const getTreeSelectOptions = <
  T extends {
    title: string;
    id: number | string;
    sublayers: T[];
    subobjects: unknown[];
    checked?: boolean;
  }
>(
  list: T[],
  editable: T | null
): ISelectOption[] => {
  const options = list.map(
    ({ title, id, sublayers, checked }) =>
      ({
        label: title,
        value: id,
        checked,
        children: sublayers?.length
          ? getTreeSelectOptions(sublayers, editable)
          : null,
      } as ISelectOption)
  );

  if (editable) {
    if (options.some((item: ISelectOption) => item.value === editable.id)) {
      return options.filter((option) => option.value !== editable.id);
    }

    const findParent = (
      acc: ISelectOption | null,
      item: ISelectOption
    ): ISelectOption | null => {
      if (
        item.children?.some(
          (child: ISelectOption) => child.value === editable.id
        )
      ) {
        item.children = item.children.filter(
          (option) => option.value !== editable.id
        );

        return null;
      }

      if (item.children) return item.children.reduce(findParent, acc);

      return acc;
    };

    options.reduce(findParent, null);
  }

  return options;
};

export const getFilteredOptions = (
  list: ISelectOption[],
  value: string
): ISelectOption[] => {
  if (value) {
    return list.filter((item: ISelectOption) => {
      if (item.children) {
        item.children = getFilteredOptions(item.children, value);

        if (item.children.length) {
          return true;
        }
      }

      return item.label.toLowerCase().includes(value.toLowerCase());
    });
  }

  return list;
};

export const getMediaFiles = (
  files: Array<string | Omit<IMediaFile, 'type'> | IMediaFile>
): IMediaFile[] => {
  if (typeof files === 'string') {
    return [
      {
        url: files,
        type: isVideo(files) ? 'video' : 'image',
      },
    ];
  }

  if (files.length) {
    return files.map((item) => ({
      ...(typeof item === 'object' ? item : {}),
      url: typeof item === 'object' ? item.url : item,
      type: isVideo(
        (item as IMediaFile).file
          ? ((item as IMediaFile)?.file as File).name
          : typeof item === 'object'
          ? item.url
          : item
      )
        ? 'video'
        : 'image',
    }));
  }

  return [];
};

export const getMonospacedString = (value: string) => `\`${value}\``;

export const maskPassword = (password: string) => password.replace(/./g, '*');

export const getWordDayForm = (
  quantity = 0,
  words: [string, string, string]
) => {
  const string = quantity.toString();
  const lastChar = string.charAt(string.length - 1);

  switch (true) {
    case lastChar === '1' && !(quantity === 11):
      return words[0];
    case lastChar === '2' && !(quantity === 12):
    case lastChar === '3' && !(quantity === 13):
    case lastChar === '4' && !(quantity === 14):
      return words[1];

    default:
      return words[2];
  }
};

export const generatePassword = (length: number) => {
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const digits = '0123456789';

  const str = lowerCaseLetters + upperCaseLetters + digits;

  let password = '';

  for (let i = 1; i <= length; i++) {
    const char = Math.floor(Math.random() * str.length + 1);

    password += str.charAt(char);
  }

  return password;
};

export const filterOptionsByUniqueClan = (
  options: IOption[],
  players: IClanPlayer[]
) =>
  options.filter(
    (option) => !players.some((player) => player.clanId === option.value)
  );

export const mergeUniqueRecordTags = (records: IRecord[]) => {
  const uniqueTags = new Set<string>();

  records.forEach((record) => {
    if (record.tags) {
      record.tags.forEach((tag) => uniqueTags.add(tag));
    }
  });

  return Array.from(uniqueTags);
};

export const filterRecordBySelectedTags = (
  records: IRecord[],
  tags: ITag[]
) => {
  const str = tags.map((tag) => tag.title);

  return records.filter((record) => {
    if (Array.isArray(record.tags)) {
      return record.tags.some((item) => str.includes(item));
    }

    return false;
  });
};

export const getOptionsByFilteredTags = (options: IOption[], tags: string[]) =>
  tags.flatMap((tag) =>
    options.some((item) => tag.includes(item.label))
      ? [{ value: tag, label: tag }]
      : []
  );

export const filterTreeRoomsBySearch = (
  treeRooms: ITreeRooms[],
  searchValue: string
) =>
  treeRooms.filter(({ clanName, rooms }) => {
    if (clanName.includes(searchValue)) {
      return true;
    }

    if (Array.isArray(rooms)) {
      return rooms.some((room) => room.livekitName.includes(searchValue));
    }

    return false;
  });

export const getErrorMessage = (
  error: AxiosError<{ code: string; message: string }>,
  defaultMessage: string
) => {
  if (error.response?.data) {
    const { code, message } = error.response.data;

    const key = Object.keys(errorDictionary[code]).find((key) =>
      message.includes(key)
    );

    return key ? errorDictionary[code][key] : defaultMessage;
  }

  return defaultMessage;
};

export const convertRoomNameToParams = (room: string) => {
  const params = room.split(SEPARATOR);

  return {
    clan_id: params[0],
    room_name: params.slice(1).join(SEPARATOR),
  };
};
export const changeClanValue = (values: IAccountRole[]) =>
  values.length
    ? values.flatMap((value) => value.clan_name || []).join(', ')
    : 'Не выбрано';

export const filteredDataBySearchValue = (
  data: Record<string, string>[],
  searchValue: string
) =>
  data?.filter((value) =>
    Object.values(value).some((value) =>
      String(value)?.toLowerCase().includes(searchValue.toLowerCase())
    )
  );

export const updateTreeRoom = (
  treeRooms: ITreeRooms,
  activeRooms: IRoom[],
  searchParams?: URLSearchParams
) => {
  const showClansParams = searchParams?.get('showClans');
  const showAllClansParams = searchParams?.get('showAllClans');

  const currentParams: { clanId: string; hiddenRooms: string[] }[] =
    showClansParams ? JSON.parse(showClansParams) : [];

  const { rooms, clanId } = treeRooms;

  return activeRooms.map((activeRoom) => {
    const existingRoom = rooms.find(
      (room) => room.livekitName === activeRoom.livekitName
    );

    const clanParams = currentParams.find((param) => param.clanId === clanId);
    const hiddenRooms = clanParams?.hiddenRooms || [];

    const isHidden = showAllClansParams
      ? false
      : clanParams
      ? hiddenRooms.includes(activeRoom.livekitName)
      : existingRoom?.isHidden ?? true;

    return {
      ...activeRoom,
      isHidden,
    };
  });
};

export const filteredPlayerTableData = (
  filter: IFilterValue[],
  data: IPlayer[]
) => {
  const filteredPlayer = data.filter((item) =>
    filter.every(({ id, values }) => {
      if (id === 'role') {
        return item.roles.some((role) =>
          values.includes(rolesTranslateMap[role.role_name])
        );
      }

      if (id === 'status') {
        return values.includes(item.banned ? 'Забанен' : 'Активный');
      }

      if (id === 'clan') {
        return item.roles.some((role) => values.includes(role.clan_name));
      }
    })
  );

  return filteredPlayer;
};

export const compareObjects = (
  oldObject: Record<string, any>,
  newObject: Record<string, any>
) => JSON.stringify(oldObject) === JSON.stringify(newObject);

export const roomHideController = (
  treeRooms: ITreeRooms[],
  clanId: string,
  livekitName: string,
  isHidden?: boolean
): ITreeRooms[] | undefined => {
  const rooms = treeRooms.find((rooms) => rooms.clanId === clanId)?.rooms;

  const updatedRooms = rooms?.map((room) =>
    room.livekitName === livekitName
      ? {
          ...room,
          isHidden: typeof isHidden === 'boolean' ? isHidden : !room.isHidden,
        }
      : room
  );

  if (updatedRooms?.length) {
    const newTreeRooms = treeRooms.reduce((acc: ITreeRooms[], data) => {
      if (data.clanId === clanId) {
        const hiddenRooms = updatedRooms.filter((room) => room.isHidden);

        acc = [
          ...acc,
          {
            ...data,
            rooms: updatedRooms,
            isHidden:
              (typeof isHidden === 'boolean' && isHidden) ||
              !!hiddenRooms.length,
          },
        ];
      } else {
        acc = [...acc, data];
      }

      return acc;
    }, []);

    return newTreeRooms;
  }
};

export const allRoomsHideController = (
  treeRooms: ITreeRooms[],
  selectedRooms: ITreeRooms[],
  isHidden: boolean
) => {
  const updatedTreeRooms = treeRooms.map((clan) => {
    const matchingClan = selectedRooms.find(
      (room) => room.clanId === clan.clanId
    );

    if (matchingClan) {
      return {
        ...clan,
        isHidden,
        rooms: clan.rooms.map((room) => ({
          ...room,
          isHidden,
        })),
      };
    }

    return clan;
  });

  return updatedTreeRooms;
};

export const setHiddenRoomsInSearchParams = (
  searchParams: URLSearchParams,
  treeRooms: ITreeRooms[]
) => {
  const hiddenRooms = treeRooms.filter(({ rooms }) =>
    rooms.some((room) => room.isHidden)
  );

  if (!hiddenRooms.length) {
    searchParams.delete('showClans');

    return {
      ...Object.fromEntries(searchParams),
      showAllClans: 'true',
    };
  } else {
    const unhiddenTreeRooms = treeRooms?.filter(({ rooms }) =>
      rooms.some((room) => !room.isHidden)
    );

    const convertedParams = unhiddenTreeRooms.map(({ clanId, rooms }) =>
      rooms.filter((room) => !room.isHidden).length === rooms.length
        ? {
            clanId,
          }
        : {
            clanId,
            hiddenRooms: rooms.flatMap((room) =>
              room.isHidden ? room.livekitName : []
            ),
          }
    );

    if (!convertedParams.length) {
      return {};
    } else {
      searchParams.delete('showAllClans');

      return {
        ...Object.fromEntries(searchParams),
        showClans: JSON.stringify(convertedParams),
      };
    }
  }
};

export const updateHiddenRoomsInSearchParams = (
  activeClans: IClanRoomsAdapted[],
  searchParams: URLSearchParams
) => {
  const showClansParams = searchParams.get('showClans');

  const currentParams: { clanId: string; hiddenRooms: string[] }[] =
    showClansParams ? JSON.parse(showClansParams) : [];

  const convertedActiveClans = activeClans.flatMap((activeClan) => {
    const currentParam = currentParams?.find(
      (param) => param.clanId === activeClan.clanId
    );

    if (!currentParam) return [];

    const hiddenRooms = currentParam.hiddenRooms?.filter((hiddenRoom) =>
      activeClan.rooms.some((room) => hiddenRoom === room.livekitName)
    );

    if (!hiddenRooms?.length) return { clanId: activeClan.clanId };

    if (hiddenRooms?.length !== activeClan.rooms.length) {
      return {
        clanId: activeClan.clanId,
        hiddenRooms,
      };
    }

    return [];
  });

  if (JSON.stringify(convertedActiveClans) === JSON.stringify(currentParams)) {
    return activeClans.length ? undefined : {};
  }

  if (!convertedActiveClans.length) {
    return {};
  }

  const hiddenClans = convertedActiveClans.filter(
    ({ hiddenRooms }) => hiddenRooms?.length
  );

  if (hiddenClans.length) {
    searchParams.delete('showAllClans');

    return {
      ...Object.fromEntries(searchParams),
      showClans: JSON.stringify(convertedActiveClans),
    };
  }

  if (convertedActiveClans.length === activeClans.length) {
    searchParams.delete('showClans');

    return {
      ...Object.fromEntries(searchParams),
      showAllClans: 'true',
    };
  }

  searchParams.delete('showAllClans');

  return {
    ...Object.fromEntries(searchParams),
    showClans: JSON.stringify(convertedActiveClans),
  };
};

export const checkLoginValidity = (str: string) => {
  const regex = /^(?=.*[A-Za-z])[A-Za-z0-9@!#$%^&*()\-+=]+$/;

  return regex.test(str);
};

export const checkNameValidity = (str: string) => {
  const regex = /^(?!.*[/.])(?=.*[А-Яа-яЁё])[^a-zA-Z]*$/;

  return regex.test(str);
};

const checkPasswordValidity = (str: string) => {
  const hasDigit = /\d/;
  const hasLetter = /[A-Za-z]/;
  const regex = /^[A-Za-z0-9!@#$%^&*()\-=+_~\s]*$/;

  return hasDigit.test(str) && hasLetter.test(str) && regex.test(str);
};

export const getUserFormFieldsError = ({
  name,
  email,
  password,
}: Pick<TPlayerInfo, 'email' | 'name' | 'password'>) => {
  let error = {
    name: '',
    email: '',
    password: '',
  };

  const isValidLogin = checkLoginValidity(email);

  const isValidName = checkNameValidity(name);

  const isValidPassword = password ? checkPasswordValidity(password) : true;

  if (password.length && password.length < 12) {
    error = { ...error, password: 'Поле должно содержать минимум 12 символов' };
  }

  if (!isValidLogin) {
    error = {
      ...error,
      email:
        'Поле должно содержать латинские буквы. В поле не допустимы символы "/" и "."',
    };
  }

  if (!isValidName) {
    error = {
      ...error,
      name: 'Поле должно содержать кириллицу. Поле не должно содержать символы "/" и "."',
    };
  }

  if (!isValidPassword) {
    error = {
      ...error,
      password:
        'В поле обязательно должны быть буквы и цифры. Поле не должно содержать символы "/" и "."',
    };
  }

  return error;
};
