import { FC } from 'react';
import cn from 'classnames';
import { useWindowSize } from 'hooks';

interface EmtyScreenProps {
  text: string;
  isFullscreenOpen?: boolean;
  className?: string;
}

export const EmtyScreen: FC<EmtyScreenProps> = ({
  text,
  isFullscreenOpen,
  className,
}) => {
  const { height } = useWindowSize();

  const heightScreen = `${height - (isFullscreenOpen ? 20 : 105)}px`;

  return (
    <div
      className={cn(
        'flex justify-center items-center w-full bg-light rounded-[10px]',
        className
      )}
      style={{ height: heightScreen }}
    >
      <span className="tpg-h4 text-tpg_light">{text}</span>
    </div>
  );
};
