import { FC } from 'react';
import { IHistory } from 'types/history';

import { CollapseHistory } from './CollapseHistory';

interface HistoryInfoProps {
  history: IHistory[];
}

export const HistoryInfo: FC<HistoryInfoProps> = ({ history }) => (
  <div>
    {history.map((info) => (
      <CollapseHistory key={info.date} {...info} />
    ))}
  </div>
);
