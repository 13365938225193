import { FC, useEffect, useState } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { Tooltip } from '@mui/material';
import cn from 'classnames';

import './style.scss';

type TPlacementUnion =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface ITooltipProps {
  children: ReactElement;
  title: string;
  offsetX?: number;
  offsetY?: number;
  placement?: TPlacementUnion;
  tooltipClassName?: string;
  titleClassName?: string;
  idToCheckOverflow?: string;
  isTooltipShown?: boolean;
}

export const TooltipWrap: FC<ITooltipProps> = ({
  children,
  title,
  offsetX = 0,
  offsetY = 0,
  placement,
  tooltipClassName,
  titleClassName,
  idToCheckOverflow,
  isTooltipShown,
}) => {
  const [isElementOverflown, setIsElementOverflown] = useState(false);

  const shouldRenderTooltip =
    (idToCheckOverflow && isElementOverflown) ||
    (typeof isTooltipShown === 'boolean' ? isElementOverflown : true);

  useEffect(() => {
    if (!idToCheckOverflow) return;

    const targetElement = document.getElementById(idToCheckOverflow);

    if (
      targetElement &&
      targetElement.scrollWidth > targetElement.clientWidth
    ) {
      setIsElementOverflown(true);
    }
  }, []);

  useEffect(() => {
    if (typeof isTooltipShown === 'boolean') {
      setIsElementOverflown(isTooltipShown);
    }
  }, [isTooltipShown]);

  return shouldRenderTooltip ? (
    <Tooltip
      title={
        <span className={cn('tpg-c2', 'proxima-nova-font', titleClassName)}>
          {title}
        </span>
      }
      placement={placement ?? 'bottom'}
      classes={{
        tooltip: cn('tooltip', tooltipClassName),
      }}
      enterDelay={400}
      enterNextDelay={400}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [offsetX ?? 0, offsetY ?? 0],
              },
            },
          ],
        },
        tooltip: {
          sx: {
            height: 'fit-content',
            padding: '4px 8px',
            bgcolor: '#171925',
            color: '#e3e6f1',
            margin: '2px',
            marginTop: '2px !important',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
};
