import { useEffect, useMemo } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { authRoutes, navigation } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { authSelector, loggedInThunk } from 'store';

import { Button } from 'components/ui';
import { Loader } from 'components/ui/Loader';
import { getToken } from 'utils';

const { REACT_APP_DEX_SECRET } = process.env;

export const LoggedInScreen = () => {
  const { search } = useLocation();

  const { error, pending, role, isLogged, mfaRequired } =
    useAppSelector(authSelector);

  const accessToken = getToken();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const loggedInData = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    if (searchParams.size === 0) {
      return null;
    }

    return {
      code: searchParams.get('code'),
      state: searchParams.get('state'),
    };
  }, [search]);

  useEffect(() => {
    const client_secret = REACT_APP_DEX_SECRET;

    if (!isLogged && !mfaRequired && loggedInData && client_secret) {
      dispatch(
        loggedInThunk({
          ...loggedInData,
          client_secret,
        })
      );
    }
  }, [loggedInData, mfaRequired, isLogged]);

  if (isLogged && accessToken)
    return <Navigate to={role ? navigation[role] : '/'} replace />;

  return (
    <>
      {pending && (
        <Loader className="h-[calc(100%-24px)] w-[calc(100%-24px)] flex justify-center items-center" />
      )}
      {error && (
        <>
          <h2>{error}</h2>
          <Button
            onClick={() => navigate(authRoutes.SIGN_IN)}
            title="Попробовать снова"
          />
        </>
      )}
    </>
  );
};
