import {
  ILoggedInRequest,
  ILoggedInResponse,
  ILoginResponse,
  IVerifyPasswordParams,
  IVerifyPasswordResponse,
} from 'interfaces';
import axios from 'services/axios';

import {
  IAccountPasswordThunkData,
  TAccountEmailThunkData,
} from './types/account';

export const login = async () => {
  const { data } = await axios.post<ILoginResponse>(
    'login',
    {},
    { params: { access_type: 'offline' } }
  );

  return data;
};

export const loggedIn = (data: ILoggedInRequest) =>
  axios.post<ILoggedInResponse>('logged-in', data);

export const changeEmail = (data: TAccountEmailThunkData) =>
  axios.post<ILoggedInResponse>('/change-email', data);

export const changePassword = (data: IAccountPasswordThunkData) =>
  axios.post<ILoggedInResponse>('/change-password', data);

export const verifyPassword = (data: IVerifyPasswordParams) =>
  axios.post<IVerifyPasswordResponse>('/verify', data);
