import { getClanRecords, getRecord } from 'api/records';
import { asyncActionsNames } from 'constants/reducers';
import { IClanRecords, IRecord } from 'interfaces/record';

import { createThunk } from 'utils';

export const getClanRecordsThunk = createThunk<
  IClanRecords,
  { clanId: string; params?: Record<string, any> }
>(asyncActionsNames.CET_CLAN_RECORDS, getClanRecords);

export const getRecordThunk = createThunk<IRecord, string>(
  asyncActionsNames.GET_RECORD,
  getRecord
);
