import { Key, MouseEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EUserRole } from 'constants/profile';
import { NOTHING_WAS_FOUND_MESSAGE } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IAccountRole } from 'interfaces';
import { IPlayer } from 'interfaces/player';
import {
  authSelector,
  playerActions,
  playerSelector,
  updateMfaRequiredThunk,
} from 'store';
import { getClansThunk } from 'store/slices/clan/actions';
import { getAllPlayersThunk } from 'store/slices/player/actions';
import { rolesTranslateMap, rolesValuesMap, TPlayerTable } from 'types/player';

import { Switch } from 'components/ui';
import { Loader } from 'components/ui/Loader';
import STable from 'components/ui/Table/STable';
import { STableCell } from 'components/ui/Table/STableCell';
import { IFilterValue } from 'components/ui/Table/STableHeader';
import { changeClanValue, filteredPlayerTableData } from 'utils';

const getHeadCellsData = (data: IPlayer[]) => [
  {
    id: 'player',
    numeric: false,
    disablePadding: false,
    label: 'Позывной',
    sortableProperty: 'name',
  },
  {
    id: 'external_id',
    numeric: false,
    disablePadding: false,
    label: 'Логин',
    sortableProperty: 'external_id',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Роль',
    possibleValues: Array.from(
      new Set(
        data.flatMap((el) =>
          el.roles.map((role) => rolesTranslateMap[role.role_name])
        )
      )
    ),
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
    possibleValues: Array.from(
      new Set(data.flatMap((el) => (el.banned ? 'Забанен' : 'Активный')))
    ),
  },
  {
    id: 'clan',
    numeric: false,
    disablePadding: false,
    label: 'Подразделение',
    possibleValues: Array.from(
      new Set(data.flatMap((el) => changeClanValue(el.roles).split(', ')))
    ),
  },
  {
    id: 'mfa_required',
    numeric: false,
    disablePadding: false,
    label: 'Двухфакторная аутентификация',
  },
];

const changeRoleValue = (values: IAccountRole[]) =>
  values.length
    ? values
        .reduce((acc: string[], value: IAccountRole) => {
          if (value.clan_id !== '00000000000000000000000000') {
            if (value.clan_name) {
              acc.push(
                `${value.clan_name} - ${rolesTranslateMap[value.role_name]}`
              );
            } else {
              acc.push(`${rolesTranslateMap[value.role_name]}`);
            }
          }

          return acc;
        }, [])
        .join(', ')
    : 'Не выбрана';

export const UsersTable = () => {
  const dispatch = useAppDispatch();

  const { filteredList: players, pending } = useAppSelector(playerSelector);

  const { roles, id: accountId } = useAppSelector(authSelector);

  const [selectedFilters, setSelectedFilters] = useState<IFilterValue[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const search = searchParams.get('search');

  const highPriorityRoles = roles.filter(
    (role) => role.role_name === EUserRole.ADMIN
  );

  const availablePlayers =
    accountId === rolesValuesMap.root
      ? players
      : players?.filter((player) =>
          player.roles.some((role) =>
            highPriorityRoles.some(
              (highRole) => highRole.clan_id === role.clan_id
            )
          )
        );

  useEffect(() => {
    dispatch(getClansThunk());

    dispatch(getAllPlayersThunk()).then(() => {
      dispatch(playerActions.setQuerySearch(search || ''));

      const filters = searchParams.get('filters');

      if (filters) {
        setSelectedFilters(JSON.parse(filters));
      }
    });
  }, []);

  useEffect(() => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      filters: JSON.stringify(selectedFilters),
    });
  }, [selectedFilters]);

  const tableData = useMemo(
    () =>
      selectedFilters.length
        ? filteredPlayerTableData(selectedFilters, availablePlayers)
        : availablePlayers,
    [selectedFilters, availablePlayers]
  );

  const fetchRows = useMemo(
    () => async () => {
      const response: {
        documents: TPlayerTable[];
        total_count: number;
      } = await new Promise((resolve) =>
        resolve({
          total_count: tableData?.length || 0,
          documents: tableData || [],
        })
      );

      return {
        rows: response.documents,
        total: response.total_count,
      };
    },
    [selectedFilters, availablePlayers]
  );

  const ENTITIES_HEAD_CELLS = useMemo(
    () => getHeadCellsData(tableData),
    [tableData]
  );

  const navigate = useNavigate();

  const handleRowClick = (_row: TPlayerTable, rowId: Key) => {
    navigate(`/admin-panel/users/${rowId}`);
  };

  const handleTwoFactorChange = (id: string, isMfaRequired: boolean) => {
    dispatch(
      updateMfaRequiredThunk({
        account_id: id,
        mfa_required: !isMfaRequired,
      })
    ).then(() => {
      dispatch(getAllPlayersThunk());
    });
  };

  const handleCellClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  if (pending)
    return (
      <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>
    );

  return (
    <div className="relative">
      {search && !tableData.length && (
        <div className="absolute z-[2] left-1/2 -translate-x-1/2 top-[70px]">
          <span className="text-tpg_base tpg-c2 !text-lg">
            {NOTHING_WAS_FOUND_MESSAGE}
          </span>
        </div>
      )}
      <STable<TPlayerTable, string>
        headCells={ENTITIES_HEAD_CELLS}
        rowIdExtractor={(row) => row?.id}
        fetchRows={fetchRows}
        handleRowClick={handleRowClick}
        classNames={{
          root: 'group !h-[calc(100vh-148px)]',
          row: 'group hover:!bg-ultrablack',
          cell: 'group-hover:!bg-[inherit] !border-b-tpg_light !bg-light',
          body: '[&>tr>td]:h-[93px]',
        }}
        onFiltersChange={setSelectedFilters}
        selectedFilters={selectedFilters}
        renderRowCells={(row, props) => {
          const roleKey = row.roles
            .map(({ role_name }) => role_name)
            ?.join(',');

          return (
            <>
              <STableCell
                {...props}
                key={`name-${row.name}`}
                propName="name"
                cellType="text"
              >
                {row.name}
              </STableCell>
              <STableCell
                {...props}
                key={`external_id-${row.name}`}
                propName="external_id"
                cellType="text"
              >
                {row.external_id}
              </STableCell>
              <STableCell
                {...props}
                key={roleKey}
                propName="role"
                cellType="text"
              >
                {changeRoleValue(row.roles)}
              </STableCell>
              <STableCell
                {...props}
                key={`${row.status}-${row.name}`}
                propName="status"
                cellType="text"
              >
                {row.banned ? 'Забанен' : 'Активный'}
              </STableCell>
              <STableCell
                {...props}
                key={`${row.clans.length ? row.clans.join(',') : 'Нет'}-${
                  row.name
                }`}
                propName="clans"
                cellType="text"
              >
                {changeClanValue(row.roles) || 'Нет'}
              </STableCell>
              <STableCell
                {...props}
                key={`mfaRequired-${row.name}-${!!row.mfa_required}`}
                propName="mfa_required"
                cellType="checkbox"
                isEditMode={true}
              >
                <div onClick={handleCellClick}>
                  <Switch
                    checked={!!row.mfa_required}
                    disabled={
                      row.id === rolesValuesMap.root &&
                      accountId !== rolesValuesMap.root
                    }
                    onChange={() =>
                      handleTwoFactorChange(row.id, !!row.mfa_required)
                    }
                  />
                </div>
              </STableCell>
            </>
          );
        }}
      />
    </div>
  );
};
