import { createSlice } from '@reduxjs/toolkit';
import { errorMessages } from 'constants/messages';
import { reducersNames } from 'constants/reducers';
import { IPlayer } from 'interfaces/player';
import { rolesReverseMap, rolesTranslateMap } from 'types/player';

import { changeClanValue, filteredDataBySearchValue } from 'utils';
import { getHighestRole } from 'utils/getHighestRole';

import { getAllPlayersThunk } from './actions';

interface IConvertedPlayerData {
  name: string;
  banned: string;
  external_id: string;
  roles: string;
  clan: string;
}

interface IPlayerState {
  list: IPlayer[];
  filteredList: IPlayer[];
  convertedPlayersData: IConvertedPlayerData[];
  pending: boolean;
  error: string | null;
}

const initialState: IPlayerState = {
  list: [],
  filteredList: [],
  convertedPlayersData: [],
  pending: false,
  error: null,
};

const playerSlice = createSlice({
  name: reducersNames.PLAYER,
  initialState,
  reducers: {
    setQuerySearch(state, { payload }) {
      const filteredConvertedPlayerData = filteredDataBySearchValue(
        state.convertedPlayersData,
        payload
      );

      const filteredPlayer = state.list?.filter((player) =>
        filteredConvertedPlayerData?.some(
          (data) => player.external_id === data.external_id
        )
      );

      if (filteredPlayer?.length) {
        state.filteredList = filteredPlayer;
      } else {
        if (payload) {
          state.filteredList = [];

          return;
        }

        state.filteredList = state.list;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllPlayersThunk.fulfilled, (state, { payload }) => {
        const convertedPlayersData = payload.map(
          ({ name, banned, external_id, roles }) => ({
            name,
            external_id,
            banned: banned ? 'Забанен' : 'Активный',
            roles: roles
              .map((role) => rolesTranslateMap[rolesReverseMap[role.role_id]])
              .join(' ,'),
            clan: changeClanValue(roles),
          })
        );

        const convertedList = payload.map((value) => ({
          ...value,
          role: getHighestRole(value.roles),
          status: '',
          access: [],
          clans: [],
          password: '',
        }));

        state.convertedPlayersData = convertedPlayersData;
        state.list = convertedList;
        state.filteredList = convertedList;

        state.error = null;
        state.pending = false;
      })
      .addCase(getAllPlayersThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getAllPlayersThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.LOADING_ERROR;
      });
  },
});

export const { actions: playerActions, reducer: playerReducer } = playerSlice;

export * from './selectors';
