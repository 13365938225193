import axios from 'services/axios';

import { recordAdapter, recordsAdapter } from './helpers/recordsAdapter';

export const getClanRecords = async ({
  clanId,
  params,
}: {
  clanId: string;
  params?: Record<string, any>;
}) => {
  const { data } = await axios.get(`/clans/${clanId}/recordings`, {
    params,
  });

  return { clanId, records: recordsAdapter(data) };
};

export const getRecord = async (recordId: string) => {
  const { data } = await axios.get(`/recordings/${recordId}`);

  return recordAdapter(data);
};
