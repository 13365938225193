import { FC, MouseEvent, PropsWithChildren, ReactNode, useState } from 'react';
import cn from 'classnames';

interface CollapseContainerProps {
  hiddenBlock: ReactNode;
  classNameContainer?: string;
  classNameButton?: string;
}

export const CollapseContainer: FC<
  PropsWithChildren<CollapseContainerProps>
> = ({ children, hiddenBlock, classNameContainer, classNameButton }) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpenInfo = (event: MouseEvent) => {
    event.stopPropagation();

    setOpen(!isOpen);
  };

  return (
    <div className="relative">
      <div
        className={cn(
          'border border-solid rounded-t-[10px] border-dark_product',
          classNameContainer
        )}
      >
        {children}
        <div
          className={cn('flex flex-col transition-all ease-in-out', {
            'overflow-hidden !hidden': !isOpen,
          })}
        >
          {hiddenBlock}
        </div>
      </div>
      <div
        className={cn(
          'flex h-[40px] cursor-pointer rounded-b-[10px] border-solid border-b border-x border-x-dark_product border-b-dark_product bg-dark items-center justify-center tpg-c2',
          classNameButton
        )}
        onClick={handleOpenInfo}
      >
        {isOpen ? 'Скрыть' : 'Показать больше'}
      </div>
    </div>
  );
};
