import { IClan } from 'interfaces/clan';
import { IPlayer } from 'interfaces/player';
import axios from 'services/axios';
import { TClanResponse } from 'types/clan';

export const getClans = async () => {
  const { data } = await axios.get<TClanResponse[]>('/clans');

  return data;
};

export const getClan = async (id: string) => {
  const { data } = await axios.get<TClanResponse>(`clans/${id}`);

  return data;
};

export const getClanMembers = async (clanId: string) => {
  const { data } = await axios.get<IPlayer[]>(`/clans/${clanId}/members`);

  return data;
};

export const createClan = ({
  // coordinates,
  admin_id,
  name,
  work_area,
}: Pick<IClan, 'name' | 'work_area'> & {
  admin_id: string;
}) => {
  // ToDo: temporarily hidden
  // const location = coordinates[0].map(([lat, lon]) => ({
  //   lat,
  //   lon,
  // }));

  // ToDo: temporary data for executing the request
  const defaultLocation = [
    { lat: 41.1, lon: 41.2 },
    { lat: 76.2, lon: 29.3 },
    { lat: 74.7, lon: 49.5 },
    { lat: 41.1, lon: 41.1 },
  ];

  return axios.post(
    '/clans',
    {
      name,
      location: defaultLocation,
      admin_id,
      work_area,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const updateClan = async ({
  coordinates,
  id,
  name,
  users_count,
  work_area,
}: IClan) => {
  const location = coordinates[0].map(([lat, lon]) => ({
    lat,
    lon,
  }));

  const { data } = await axios.patch(
    `/clans/${id}`,
    {
      name,
      location,
      users_count,
      work_area,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return data;
};

export const deleteClan = (id: string) => axios.delete(`/clans/${id}`);

export const setClanMember = ({
  clanId,
  memberId,
  roleId,
}: TSetClanMemberInput) =>
  axios.put(`/clans/${clanId}/members/${memberId}`, undefined, {
    params: {
      role_id: roleId,
    },
  });

export const deleteClanMember = ({
  clanId,
  memberId,
}: Omit<TSetClanMemberInput, 'roleId'>) =>
  axios.delete(`/clans/${clanId}/members/${memberId}`);

export type TSetClanMemberInput = {
  clanId: string;
  memberId: string;
  roleId: string;
  isAddedFromAccount?: boolean;
  isAddedWithNewAccount?: boolean;
};
