import { useEffect, useMemo, useState } from 'react';
import { NOTHING_WAS_FOUND_MESSAGE } from 'constants/routes';
import { useAppSelector } from 'hooks';
import { IPlayer } from 'interfaces/player';
import { clansSelector } from 'store';
import { rolesValuesMap } from 'types/player';

import { TextInput } from 'components/ui';
import { getWordDayForm } from 'utils';

import { AccessCard } from './components/AccessCard';

export const AccessInfo = () => {
  const { selectedClan } = useAppSelector(clansSelector);

  const playersWithoutRoot = useMemo(
    () =>
      selectedClan?.players.filter(
        (player) => player.id !== rolesValuesMap.root
      ) || [],
    [selectedClan]
  );

  const access = useMemo(
    () =>
      playersWithoutRoot.filter(
        (player) => player.clan_id !== selectedClan?.id
      ),
    [playersWithoutRoot]
  );

  const [searchValue, setSearchValue] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState<IPlayer[]>([]);

  const wordForm = getWordDayForm(access.length, [
    'доступ',
    'доступа',
    'доступов',
  ]);

  useEffect(() => {
    if (selectedClan?.id) {
      setFilteredPlayers(access);
    }
  }, [selectedClan, access]);

  const handleChangeSearch = (value: string) => {
    setSearchValue(value);

    const filteredData = value
      ? access.filter(({ name }) =>
          name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        )
      : access;

    setFilteredPlayers(filteredData);
  };

  return (
    <>
      <div className="flex justify-start bg-ultrablack px-[16px] pt-[16px] pb-[8px] sticky top-0 z-[2]">
        <span>{`${access.length} ${wordForm}`}</span>
      </div>
      {access.length > 10 && (
        <TextInput
          onChange={handleChangeSearch}
          value={searchValue}
          placeholder="Начните поиск по пользователям"
          searchIcon
          isReset
          inputClassName="!bg-ultrablack !h-[32px] !pl-[32px]"
          className="!border !border-solid !border-tpg_light rounded-[5px] [&>div>svg]:!ml-[12px] [&>div>svg]:!w-4 [&>div>svg]:!h-4 mx-[16px] mb-[8px]"
        />
      )}
      <div className="flex flex-col gap-[16px] px-[16px] pt-[8px] pb-[16px]">
        {!!filteredPlayers.length &&
          selectedClan?.id &&
          filteredPlayers.map(({ name, clan_name, roles, id }) => (
            <AccessCard
              key={name}
              clanId={selectedClan.id}
              userId={id}
              name={name}
              originClanName={clan_name}
              roles={roles}
            />
          ))}
        {!filteredPlayers.length && searchValue && (
          <span className="text-tpg_base tpg-c2">
            {NOTHING_WAS_FOUND_MESSAGE}
          </span>
        )}
      </div>
    </>
  );
};
