import { FC, ReactNode, useState } from 'react';
import cn from 'classnames';
import { useBreakPoint } from 'hooks';
import { ReactComponent as CheckIcon } from 'images/newIcons/check.svg';
import { ReactComponent as TrashIcon } from 'images/newIcons/trash.svg';

interface AdminTableProps {
  title: string;
  buttonTitle: string;
  historyTitle: string;
  listTitle: string;
  isShowFooterController: boolean;
  info: ReactNode;
  history: ReactNode;
  list: ReactNode;
  accessTitle?: string;
  access?: ReactNode;
  onSave?: () => void;
  onRemove?: () => void;
  isNewData?: boolean;
}

export const AdminTable: FC<AdminTableProps> = ({
  title,
  buttonTitle,
  // historyTitle,
  listTitle,
  isShowFooterController = false,
  info,
  history,
  list,
  accessTitle,
  access,
  onSave,
  onRemove,
  isNewData,
}) => {
  const [isOpenHistory, setOpenHistory] = useState(false);

  const breakPoint = useBreakPoint();

  // const [isHistoryOpen, setHistoryOpen] = useState(false);
  // const [isAccessOpen, setAccessOpen] = useState(false);
  // const [isTabsOpen, setTabsOpen] = useState(false);

  // const [searchParams, setSearchParams] = useSearchParams();

  // const containerRef = useRef<HTMLDivElement>(null);

  // useClickOutside<HTMLDivElement, void>(containerRef, () => setTabsOpen(false));

  // useEffect(() => {
  //   const tab = searchParams.get('tab');

  //   if (tab === 'users') {
  //     setHistoryOpen(false);
  //     setAccessOpen(false);
  //   }

  //   if (tab === 'access') {
  //     setHistoryOpen(false);
  //     setAccessOpen(true);
  //   }
  // }, [searchParams]);

  // const handleListSelect = () => {
  //   setHistoryOpen(false);
  //   setAccessOpen(false);

  //   setSearchParams({
  //     tab: 'users',
  //   });
  // };

  // const handleAccessSelect = () => {
  //   setHistoryOpen(false);
  //   setAccessOpen(true);

  //   setSearchParams({
  //     tab: 'access',
  //   });
  // };

  // const handleTabsOpen = () => {
  //   setTabsOpen(!isTabsOpen);
  // };

  // const handleSelectHistory = () => {
  //   setOpenHistory(true);
  // };

  const handleSelectList = () => {
    setOpenHistory(false);
  };

  const isMobile = breakPoint === 'mobile' || breakPoint === 'tablet';

  return (
    <div className="border w-full border-solid rounded-[10px] border-tpg_light h-full overflow-hidden">
      <div className="flex flex-row justify-between px-[12px] py-[10px] rounded-t-[10px] bg-dark border-solid border-b border-b-tpg_light">
        <span>{title}</span>
        <div className="flex flex-row gap-[16px]">
          <span
            className={cn(
              'text-tpg_base pointer-events-none',
              // cursor-pointer hover:text-bright_product transition
              {
                // '!text-bright_product': !isOpenHistory,
              }
            )}
            onClick={handleSelectList}
          >
            {listTitle}
          </span>
          {/* TODO: return when history data is ready */}
          {/* {!isNewData && (
            <span
              className={cn(
                'text-tpg_base cursor-pointer hover:text-bright_product transition',
                {
                  '!text-bright_product': isOpenHistory,
                }
              )}
              onClick={handleSelectHistory}
            >
              {historyTitle}
            </span>
          )} */}
        </div>
        {/* ToDo: temporarily hidden for future access logic */}
        {/* {isMobile && (
          <div ref={containerRef} className="relative">
            <EllipsisIcon onClick={handleTabsOpen} />
            {isTabsOpen && (
              <div className="flex flex-col absolute right-[0] z-[3] rounded-[10px] bg-ultrablack gap-[10px] p-[10px] border border-solid border-tpg_light">
                <span
                  className={cn('text-tpg_base', {
                    '!text-bright_product':
                      !isHistoryOpen && !isAccessOpen && accessTitle,
                    'cursor-pointer hover:text-bright_product transition':
                      accessTitle,
                  })}
                  onClick={handleListSelect}
                >
                  {listTitle}
                </span>
                {accessTitle && (
                  <span
                    className={cn(
                      'cursor-pointer hover:text-bright_product transition text-tpg_base',
                      {
                        '!text-bright_product': !isHistoryOpen && isAccessOpen,
                      }
                    )}
                    onClick={handleAccessSelect}
                  >
                    {accessTitle}
                  </span>
                )}
                TODO: return when history data is ready
                {!isNewData && (
                  <span
                    className={cn(
                      'text-tpg_base cursor-pointer hover:text-bright_product transition',
                      {
                        '!text-bright_product': isOpenHistory,
                      }
                    )}
                    onClick={handleSelectHistory}
                  >
                    {historyTitle}
                  </span>
                )}
              </div>
            )}
          </div>
        )}
        {!isMobile && (
          <div className="flex flex-row gap-[16px]">
            <span
              className={cn('text-tpg_base', {
                '!text-bright_product':
                  !isHistoryOpen && !isAccessOpen && accessTitle,
                'cursor-pointer hover:text-bright_product transition':
                  accessTitle,
              })}
              onClick={handleListSelect}
            >
              {listTitle}
            </span>
            {accessTitle && (
              <span
                className={cn(
                  'cursor-pointer hover:text-bright_product transition text-tpg_base',
                  {
                    '!text-bright_product': !isHistoryOpen && isAccessOpen,
                  }
                )}
                onClick={handleAccessSelect}
              >
                {accessTitle}
              </span>
            )}
            TODO: return when history data is ready
            {!isNewData && (
              <span
                className={cn(
                  'text-tpg_base cursor-pointer hover:text-bright_product transition',
                  {
                    '!text-bright_product': isOpenHistory,
                  }
                )}
                onClick={handleSelectHistory}
              >
                {historyTitle}
              </span>
            )}
          </div>
        )} */}
      </div>
      <div
        className={cn('flex flex-row h-[calc(100%-40px)] overflow-auto', {
          'h-[calc(100%-79px)]': isShowFooterController,
          'flex-col': isMobile,
        })}
      >
        {info}
        {isOpenHistory ? history : list}
        {/* <div
          className={cn('w-6/12 min-h-full overflow-auto', {
            'w-full': isMobile,
          })}
        >
          {!isHistoryOpen && isAccessOpen && access}
          {isHistoryOpen && !isAccessOpen && history}
          {!isHistoryOpen && !isAccessOpen && list}
        </div> */}
      </div>
      {isShowFooterController && (
        <div className="flex flex-row justify-end px-[12px] py-[10px] rounded-[10px] bg-dark h-[40px] border-solid border-t border-t-tpg_light">
          <div className="flex flex-row gap-[12px]">
            {!isNewData && onRemove && (
              <div
                className="group gap-[4px] flex flex-row cursor-pointer items-center hover:text-bright_product transition"
                onClick={onRemove}
              >
                <TrashIcon className="group-hover:[&>path]:fill-bright_product w-[16px] h-[16px] [&>path]:transition" />
                <span className="tpg-c2">{buttonTitle}</span>
              </div>
            )}
            {onSave && (
              <div
                className="gap-[4px] flex flex-row cursor-pointer items-center hover:text-bright_product transition"
                onClick={onSave}
              >
                <CheckIcon className="[&>path]:fill-green" />
                <span className="tpg-c2">Сохранить</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
