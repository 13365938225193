import { FC, useEffect } from 'react';
import { useAppDispatch } from 'hooks';
import { getClansThunk } from 'store/slices/clan/actions';

import { AllClansStreamsPanel } from './AllClansStreamsPanel';

interface StreamsPanelScreenProps {
  isFullscreenOpen: boolean;
  onFullscreenClose: (flag: boolean) => void;
}

export const StreamsPanelScreen: FC<StreamsPanelScreenProps> = ({
  isFullscreenOpen,
  onFullscreenClose,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getClansThunk());

    return () => {
      onFullscreenClose(false);
    };
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        onFullscreenClose(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const handleClickBack = () => {
    const event = new Event('customExitFullscreen');
    document.dispatchEvent(event);
  };

  useEffect(() => {
    const handleCustomExitFullscreen = () => {
      onFullscreenClose(false);

      if (document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen().then(() => onFullscreenClose(false));
      }
    };

    document.addEventListener(
      'customExitFullscreen',
      handleCustomExitFullscreen
    );

    return () => {
      document.removeEventListener(
        'customExitFullscreen',
        handleCustomExitFullscreen
      );
    };
  }, []);

  return (
    <AllClansStreamsPanel
      isFullscreenOpen={isFullscreenOpen}
      onBack={handleClickBack}
    />
  );
};
