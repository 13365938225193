import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAccountMe } from 'api/account';
import { loggedIn, login } from 'api/auth';
import { checkMfaParams, updateMfaRequired } from 'api/mfa';
import { getRoles } from 'api/role';
import { AxiosResponse } from 'axios';
import { errorMessages, successMessages } from 'constants/messages';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import {
  ILoggedInRequest,
  ILoggedInResponse,
  ILoginResponse,
  IMfaParams,
  IMfaRequiredParams,
} from 'interfaces';
import { IPlayerResponse } from 'interfaces/player';

import { createThunk, notify } from 'utils';
import { setLStorageMany } from 'utils/helpers/localStorage';

export const loginThunk = createThunk<ILoginResponse>(
  `${reducersNames.AUTH}/${asyncActionsNames.LOGIN}`,
  login
);

export const loggedInThunk = createAsyncThunk<
  AxiosResponse<ILoggedInResponse>,
  ILoggedInRequest
>(
  `${reducersNames.LOGGED_IN}/${asyncActionsNames.LOGIN}`,
  async (request, thunkAPI) => {
    try {
      const loggedData = await loggedIn(request).then(async (res) => {
        const {
          data: { refresh_token, token, mfa_required, mfa_state },
        } = res;

        if (mfa_required && mfa_state) {
          return res;
        }

        setLStorageMany({
          refresh_token,
          token,
        });

        await thunkAPI.dispatch(getAccountMeThunk());

        return res;
      });

      return loggedData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const checkMfaParamsThunk = createAsyncThunk<
  AxiosResponse<ILoggedInResponse>,
  IMfaParams
>(
  `${reducersNames.LOGGED_IN}/${asyncActionsNames.CHECK_MFA_PARAMS}`,
  async (request, thunkAPI) => {
    try {
      const loggedData = await checkMfaParams(request).then(async (res) => {
        const {
          data: { refresh_token, token },
        } = res;

        setLStorageMany({
          refresh_token,
          token,
        });

        await thunkAPI.dispatch(getAccountMeThunk());

        return res;
      });

      return loggedData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getRolesThunk = createThunk(
  `${reducersNames.AUTH}/${asyncActionsNames.GET_ROLES}`,
  getRoles,
  { errorMessage: errorMessages.GET_PRIVILEGES_ERROR }
);

export const getAccountMeThunk = createThunk<IPlayerResponse, void>(
  `${reducersNames.AUTH}/${asyncActionsNames.GET_ACCOUNT_ME}`,
  getAccountMe,
  { errorMessage: errorMessages.GET_ACCOUNT }
);

export const updateMfaRequiredThunk = createAsyncThunk<
  Pick<IMfaRequiredParams, 'mfa_required'>,
  IMfaRequiredParams
>(
  `${reducersNames.AUTH}/${asyncActionsNames.UPDATE_MFA_REQUIRED}`,
  async (request) => {
    try {
      const { data } = await updateMfaRequired(request);

      notify.success(successMessages.SAVE_DATA);

      return { ...data, mfa_required: request.mfa_required };
    } catch (error) {
      notify.error(errorMessages.UPDATE_MFA_REQUIRED);
    }
  }
);
