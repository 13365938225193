import { IPlayer } from 'interfaces/player';
import axios from 'services/axios';

import { IAccountRoleThunkData, IAccountThunkData } from './types/account';

export const getAccount = async (accountId: string) => {
  const { data } = await axios.get<IPlayer>(`/accounts/${accountId}`);

  return data;
};

export const getAccountMe = async () => {
  const { data } = await axios.get<IPlayer>('/accounts/me');

  return data;
};

export const getAccounts = async () => {
  const { data } = await axios.get<IPlayer[]>('/accounts');

  return data;
};

export const updateAccount = ({
  id,
  name,
  metadata,
}: Omit<IAccountThunkData, 'clanId'>) =>
  axios.patch(`/accounts/${id}`, {
    name,
    metadata,
  });

export const updateAccountRole = ({
  role_id,
  account_id,
  clan_id,
}: IAccountRoleThunkData) =>
  axios.put(`/accounts/${account_id}/roles`, {
    role_id,
    clan_id,
  });

export const banAccount = (id: string) =>
  axios.post('/ban', { account_id: id });
