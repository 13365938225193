import { FC, memo } from 'react';
import cn from 'classnames';

import './style.scss';

type SwitchSizeUnion = 's' | 'm';

export interface SwitchProps {
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
  size?: SwitchSizeUnion;
}

const Switch: FC<SwitchProps> = ({
  checked,
  disabled,
  onChange,
  size = 's',
}) => (
  <label className={`switch-${size}`}>
    <input
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
    <span
      className={cn(`switch-${size}__handle`, {
        '!cursor-not-allowed !opacity-60': disabled,
      })}
    />
  </label>
);

export default memo(Switch);
