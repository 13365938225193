import { FC, useState } from 'react';
import { TSaveRecordParams } from 'interfaces/record';
import { TStreamStatus } from 'types';

import { Button } from 'components/ui';

interface IHostControlPanelProps {
  status: TStreamStatus;
  isPlay: boolean;
  isRecording?: boolean;
  onStart: () => void;
  onPause: () => void;
  onResume: () => void;
  onStop: (params?: TSaveRecordParams) => void;
}

export const HostControlPanel: FC<IHostControlPanelProps> = ({
  status,
  isPlay,
  isRecording,
  onStart,
  onPause,
  onResume,
  onStop,
}) => {
  const [isOpenControllerModal, setOpenControllerModal] = useState(false);

  const handleOpenDialog = () => {
    if (isRecording) {
      setOpenControllerModal(true);
    } else {
      onStop();
    }
  };

  return (
    <div className="flex flex-row gap-3 pt-6 w-full justify-center">
      {status === 'offline' && (
        <Button
          className="w-full max-w-[350px] hover:bg-dark bg-inherit border-2 border-solid border-dark_product"
          title="Начать"
          onClick={onStart}
        />
      )}
      {status !== 'offline' && (
        <Button
          onClick={isPlay ? onPause : handleOpenDialog}
          title={isPlay ? 'Пауза' : 'Завершить'}
          className="w-full max-w-[350px] hover:bg-dark bg-inherit border-2 border-solid border-dark_product"
        />
      )}
      <Button
        onClick={isPlay ? handleOpenDialog : onResume}
        title={isPlay ? 'Завершить' : 'Возобновить'}
        className="w-full max-w-[350px]"
      />
    </div>
  );
};
