import {
  ILoggedInResponse,
  IMeMfaResponse,
  IMfaParams,
  IMfaRequiredParams,
} from 'interfaces';
import axios from 'services/axios';

export const checkMfaParams = (data: IMfaParams) =>
  axios.post<ILoggedInResponse>('/mfa', data);

export const updateMfaRequired = ({
  account_id,
  mfa_required,
}: IMfaRequiredParams) =>
  axios.put(`/accounts/${account_id}/mfa-settings`, {
    mfa_required,
  });

export const getMeMfa = (mfaState: string) =>
  axios.post<IMeMfaResponse>('/accounts/me/mfa', { mfa_state: mfaState });
