import { FC, MouseEvent, useState } from 'react';
import { ReactComponent as Copy } from 'images/newIcons/copy.svg';

interface CopyIconProps {
  value: string;
}

export const CopyIcon: FC<CopyIconProps> = ({ value }) => {
  const [isCopied, setCopied] = useState(false);

  const handleCopy = (event: MouseEvent) => {
    event.stopPropagation();

    if (value) {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(value);
      } else {
        // for mobile devices and older browsers
        const textarea = document.createElement('textarea');

        textarea.value = value;
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';

        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }

      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };

  return (
    <div className="relative">
      <div
        className="group cursor-pointer flex items-center justify-center transition"
        onClick={handleCopy}
      >
        <Copy className="group-hover:[&>path]:fill-main_product [&>path]:transition" />
      </div>
      {isCopied && (
        <div className="absolute bottom-[20px] right-[-14px] rounded-[5px] flex p-[4px] bg-dark">
          <span className="tpg-c1">Скопировано!</span>
        </div>
      )}
    </div>
  );
};
